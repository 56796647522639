// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-aup-js": () => import("./../../../src/pages/aup.js" /* webpackChunkName: "component---src-pages-aup-js" */),
  "component---src-pages-clients-js": () => import("./../../../src/pages/clients.js" /* webpackChunkName: "component---src-pages-clients-js" */),
  "component---src-pages-company-about-vindicia-js": () => import("./../../../src/pages/company/about-vindicia.js" /* webpackChunkName: "component---src-pages-company-about-vindicia-js" */),
  "component---src-pages-company-awards-js": () => import("./../../../src/pages/company/awards.js" /* webpackChunkName: "component---src-pages-company-awards-js" */),
  "component---src-pages-company-become-partner-js": () => import("./../../../src/pages/company/become-partner.js" /* webpackChunkName: "component---src-pages-company-become-partner-js" */),
  "component---src-pages-company-careers-js": () => import("./../../../src/pages/company/careers.js" /* webpackChunkName: "component---src-pages-company-careers-js" */),
  "component---src-pages-company-partners-js": () => import("./../../../src/pages/company/partners.js" /* webpackChunkName: "component---src-pages-company-partners-js" */),
  "component---src-pages-company-refer-business-js": () => import("./../../../src/pages/company/refer-business.js" /* webpackChunkName: "component---src-pages-company-refer-business-js" */),
  "component---src-pages-company-team-js": () => import("./../../../src/pages/company/team.js" /* webpackChunkName: "component---src-pages-company-team-js" */),
  "component---src-pages-company-upcoming-events-js": () => import("./../../../src/pages/company/upcoming-events.js" /* webpackChunkName: "component---src-pages-company-upcoming-events-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-hacking-growth-js": () => import("./../../../src/pages/hacking-growth.js" /* webpackChunkName: "component---src-pages-hacking-growth-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-preview-js": () => import("./../../../src/pages/preview.js" /* webpackChunkName: "component---src-pages-preview-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-resources-materials-js": () => import("./../../../src/pages/resources/materials.js" /* webpackChunkName: "component---src-pages-resources-materials-js" */),
  "component---src-pages-roi-js": () => import("./../../../src/pages/roi.js" /* webpackChunkName: "component---src-pages-roi-js" */),
  "component---src-pages-sports-js": () => import("./../../../src/pages/sports.js" /* webpackChunkName: "component---src-pages-sports-js" */),
  "component---src-pages-technical-center-customer-deployment-solutions-js": () => import("./../../../src/pages/technical-center/customer-deployment-solutions.js" /* webpackChunkName: "component---src-pages-technical-center-customer-deployment-solutions-js" */),
  "component---src-pages-technical-center-vindicia-glossary-js": () => import("./../../../src/pages/technical-center/vindicia-glossary.js" /* webpackChunkName: "component---src-pages-technical-center-vindicia-glossary-js" */),
  "component---src-pages-terms-use-js": () => import("./../../../src/pages/terms-use.js" /* webpackChunkName: "component---src-pages-terms-use-js" */),
  "component---src-pages-webinars-js": () => import("./../../../src/pages/webinars.js" /* webpackChunkName: "component---src-pages-webinars-js" */),
  "component---src-templates-author-detail-js": () => import("./../../../src/templates/author-detail.js" /* webpackChunkName: "component---src-templates-author-detail-js" */),
  "component---src-templates-blog-category-js": () => import("./../../../src/templates/blog-category.js" /* webpackChunkName: "component---src-templates-blog-category-js" */),
  "component---src-templates-blog-detail-js": () => import("./../../../src/templates/blog-detail.js" /* webpackChunkName: "component---src-templates-blog-detail-js" */),
  "component---src-templates-blog-list-js": () => import("./../../../src/templates/blog-list.js" /* webpackChunkName: "component---src-templates-blog-list-js" */),
  "component---src-templates-campaign-lp-js": () => import("./../../../src/templates/campaign-lp.js" /* webpackChunkName: "component---src-templates-campaign-lp-js" */),
  "component---src-templates-client-category-js": () => import("./../../../src/templates/client-category.js" /* webpackChunkName: "component---src-templates-client-category-js" */),
  "component---src-templates-client-list-js": () => import("./../../../src/templates/client-list.js" /* webpackChunkName: "component---src-templates-client-list-js" */),
  "component---src-templates-custom-template-js": () => import("./../../../src/templates/custom-template.js" /* webpackChunkName: "component---src-templates-custom-template-js" */),
  "component---src-templates-datasheets-detail-js": () => import("./../../../src/templates/datasheets-detail.js" /* webpackChunkName: "component---src-templates-datasheets-detail-js" */),
  "component---src-templates-ebooks-detail-js": () => import("./../../../src/templates/ebooks-detail.js" /* webpackChunkName: "component---src-templates-ebooks-detail-js" */),
  "component---src-templates-faq-detail-js": () => import("./../../../src/templates/faq-detail.js" /* webpackChunkName: "component---src-templates-faq-detail-js" */),
  "component---src-templates-guide-js": () => import("./../../../src/templates/guide.js" /* webpackChunkName: "component---src-templates-guide-js" */),
  "component---src-templates-infographics-detail-js": () => import("./../../../src/templates/infographics-detail.js" /* webpackChunkName: "component---src-templates-infographics-detail-js" */),
  "component---src-templates-landing-page-js": () => import("./../../../src/templates/landing-page.js" /* webpackChunkName: "component---src-templates-landing-page-js" */),
  "component---src-templates-mini-site-js": () => import("./../../../src/templates/mini-site.js" /* webpackChunkName: "component---src-templates-mini-site-js" */),
  "component---src-templates-news-detail-js": () => import("./../../../src/templates/news-detail.js" /* webpackChunkName: "component---src-templates-news-detail-js" */),
  "component---src-templates-news-list-js": () => import("./../../../src/templates/news-list.js" /* webpackChunkName: "component---src-templates-news-list-js" */),
  "component---src-templates-press-list-js": () => import("./../../../src/templates/press-list.js" /* webpackChunkName: "component---src-templates-press-list-js" */),
  "component---src-templates-press-release-detail-js": () => import("./../../../src/templates/press-release-detail.js" /* webpackChunkName: "component---src-templates-press-release-detail-js" */),
  "component---src-templates-reports-detail-js": () => import("./../../../src/templates/reports-detail.js" /* webpackChunkName: "component---src-templates-reports-detail-js" */),
  "component---src-templates-resource-category-js": () => import("./../../../src/templates/resource-category.js" /* webpackChunkName: "component---src-templates-resource-category-js" */),
  "component---src-templates-solutions-js": () => import("./../../../src/templates/solutions.js" /* webpackChunkName: "component---src-templates-solutions-js" */),
  "component---src-templates-subscription-management-detail-js": () => import("./../../../src/templates/subscription-management-detail.js" /* webpackChunkName: "component---src-templates-subscription-management-detail-js" */),
  "component---src-templates-video-detail-js": () => import("./../../../src/templates/video-detail.js" /* webpackChunkName: "component---src-templates-video-detail-js" */),
  "component---src-templates-webinar-detail-js": () => import("./../../../src/templates/webinar-detail.js" /* webpackChunkName: "component---src-templates-webinar-detail-js" */),
  "component---src-templates-whitepapers-detail-js": () => import("./../../../src/templates/whitepapers-detail.js" /* webpackChunkName: "component---src-templates-whitepapers-detail-js" */)
}

